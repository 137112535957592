import { Alert } from '@mui/material';
import {
  AppMain,
  AvatarIconFromSvg,
  GetInTouchLink,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleMedium,
} from '@noah-labs/fe-shared-ui-components';
import { type TpCryptoNetworkUI } from '@noah-labs/fe-shared-ui-shared';

export type PpSelectNetworkScene = {
  action: 'send' | 'receive';
  cryptoNetworks: Array<TpCryptoNetworkUI>;
  getSelectedNetworkPath: (network: TpCryptoNetworkUI) => string;
  onSelectNetwork?: () => void;
};

export function SelectNetworkScene({
  action,
  cryptoNetworks,
  getSelectedNetworkPath,
  onSelectNetwork,
}: PpSelectNetworkScene): React.ReactElement {
  return (
    <AppMain>
      <SceneHeader dense>
        <SceneTitleMedium>Select network</SceneTitleMedium>
        <SceneParagraph>
          Choose the currency network on which you want to {action} funds.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense dataQa="select-network">
        <List spacing={1}>
          {cryptoNetworks.map((cryptoNetwork) => (
            <ListItem key={cryptoNetwork.id} data-qa={`network-${cryptoNetwork.id}`}>
              <ListItemButton
                href={getSelectedNetworkPath(cryptoNetwork)}
                onClick={onSelectNetwork}
              >
                <ListItemCard>
                  <ListItemContent>
                    <ListItemIcon>
                      <AvatarIconFromSvg svg={cryptoNetwork.svg} />
                    </ListItemIcon>
                    <ListItemStartContent>
                      <ListItemPrimaryText>{cryptoNetwork.label}</ListItemPrimaryText>
                      {cryptoNetwork.description && (
                        <ListItemSecondaryText>{cryptoNetwork.description}</ListItemSecondaryText>
                      )}
                    </ListItemStartContent>
                  </ListItemContent>
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Alert severity="warning">
          Please ensure you are using the correct network. It is important and using the wrong one
          may result in the loss of funds. Please <GetInTouchLink text="get help" /> if you are
          unsure.
        </Alert>
      </SceneMain>
    </AppMain>
  );
}
