import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { getAvailableCryptoNetworks, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { SelectNetworkScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, Network } from '@noah-labs/shared-schema-gql';

const NETWORKS_TO_DISPLAY = [
  Network.Ethereum,
  Network.EthereumTestSepolia,
  Network.PolygonPos,
  Network.PolygonTestAmoy,
];

type PpWithdrawNetworkController = {
  onSelectNetwork?: () => void;
};
export function WithdrawNetworkController({
  onSelectNetwork,
}: PpWithdrawNetworkController): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const { cryptoCurrency, params } = useWalletParams();

  const withdrawFF = userData?.userProfile.features.get(Feature.Withdraw);
  const cryptoNetworks = getAvailableCryptoNetworks(
    cryptoCurrency.code,
    withdrawFF,
    NETWORKS_TO_DISPLAY,
  );

  return (
    <SelectNetworkScene
      action="send"
      cryptoNetworks={cryptoNetworks}
      getSelectedNetworkPath={(network): string =>
        generatePath(walletRoutes().send.enterAmount.path, { ...params, network: network.id })
      }
      onSelectNetwork={onSelectNetwork}
    />
  );
}
